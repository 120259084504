const punterKey = 'punter2'
const punter = () => getCookie(punterKey)
let loading = false
let error = ''
let needsCaptcha = false
let debugCode = null
let debugText = null

const errorMessages = {
    GENERAL: 'Error when attempting to sign in. Please try again.',
    INVALID_CREDENTIALS: 'You have entered invalid credentials. Please try again.',
    ACCOUNT_TYPE: 'Your account type is not supported. Please sign in with a NYRA Bets account.',
}

const ENDPOINT = 'https://brk0201-iapi-webservice.nyrabets.com/EstablishSession.aspx'
const PUNTER_DETAILS_ENDPOINT = 'https://brk0201-iapi-webservice.nyrabets.com/GetPunterDetails.aspx'

function init() {
    const forms = document.querySelectorAll('[data-login-form]')
    const logouts = document.querySelectorAll('[data-auth-logout]')
    forms.forEach(initLoginForm)
    logouts.forEach(item => item.addEventListener('click', handleLogout))
    update()
}

function update() {
    const data = getLoginCookieData()
    // Usarname
    document.querySelectorAll('[data-auth-username]').forEach(item => {
        item.innerText = isLoggedIn() && data?.name ? data.name.split("\n").at(0) : 'Account'
    })
    // Error
    document.querySelectorAll('[data-auth-error]').forEach(item => {
        item.innerText = error
    })
    // Debug
    // document.querySelectorAll('[data-auth-debug]').forEach(item => {
    //     item.innerHTML = debugCode === null ? '' : `
    //     <details>
    //         <summary class="font-semibold text-base">Login return code ${debugCode}</summary>
    //         <pre class="pt-2">${debugText}</pre>
    //     </details>
    //     <details>
    //         <summary class="font-semibold text-base">Stored Cookie</summary>
    //         <pre>${JSON.stringify(JSON.parse(punter()), null, 4)}</pre>
    //     </details>
    //     `
    // })
    // Auth and Loading State
    document.querySelectorAll('[data-auth-watch]').forEach(item => {
        item.dataset.isAuthorized = isLoggedIn()
        item.dataset.isAuthLoading = loading
        item.dataset.isAuthError = !!error
    })
}

function setLoading(value) {
    loading = !!value
    update()
}

function setError(value) {
    error = value
    update()
}

function setNeedsCaption(value) {
    needsCaptcha = !!value
    update()
}

function initLoginForm(form) {
    if (!form) return
    if (form.dataset.loginFormInitialized) return
    form.dataset.loginFormInitialized = true
    form.addEventListener('submit', async event => {
        event.preventDefault()
        setLoading(true)
        const data = new FormData(event.target)
        const username = data.get('username')
        const password = data.get('password')
        let recaptchaToken = null

        if (username && password) {
            const authData = {
                "header":{
                    "version":2,
                    "fragmentLanguage":"Javascript",
                    "fragmentVersion":"",
                    "clientIdentifier":"nyra.com"
                },
                "partnerUsername": username,
                "cleartextPassword": password,
                "currency":"USD",
                "integrationPartnerId":7,
                "language":"en",
                "clientIdentifier":"nyra.com",
                "granularChannelType":5,
                "channelInformation":"NYRA Rewards website - NYRAPLUS",
                "initialLanguage":"en",
                "numberLogonAttemptsToReturn":0,
                "sessionCategory":1
            }

            if (recaptchaToken){
                authData.reCAPTCHAToken = recaptchaToken
            }

            try {
                const encodedData = 'request=' + encodeURIComponent(JSON.stringify(authData))
                const response = await fetch(
                    ENDPOINT, 
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },  
                        body: encodedData
                    }
                )
                if (!response.ok) {
                    throw new Error('Invalid auth response')
                }
                const data = await response.json()
                await handleLoginResponse(data)
            }
            catch (e) {
                console.error(e)
                setError(errorMessages.GENERAL)
            }
        }
        setLoading(false)
    })
}

async function handleLoginResponse(data) {
    if (!data) return

    // Debugging
    debugCode = data.returnCode
    debugText = JSON.stringify(data, null, 4)

    // Needs captcha
    if (data.returnCode == 958) {
        return
    }

    // Unverified user
    if (data.returnCode == 848) {
        return
    }

    // Invalid credentials
    if (data.returnCode != 0) {
        setError(errorMessages.INVALID_CREDENTIALS)
    }
    // Successful login
    else {
        let cookieData = {
            name: 'Account',
            isNyraBetsUser: "Basic" === data.punterClassificationName,
            token: data.sessionToken,
        }

        // Clear any previously set cookies.
        setCookie('jwt', 'clear', true)
        setCookie(punterKey, 'clear', true)

        if (!cookieData.isNyraBetsUser) {
            setError(errorMessages.ACCOUNT_TYPE)
            logout()
            return
        }

        // Get punter details
        const punterPayload = {
            "header": {
                "version": 2,
                "fragmentLanguage": "Javascript",
                "fragmentVersion": "",
                "clientIdentifier": "nyra.com"
            },
            "sessionToken": data.sessionToken
        }
        try {
            let response = await fetch(
                PUNTER_DETAILS_ENDPOINT, 
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },  
                    body: 'request=' + encodeURIComponent(JSON.stringify(punterPayload))
                }
            )
            if (!response.ok) {
                throw new Error('Invalid auth response')
            }
            const punterDetail = await response.json()
            if (punterDetail && punterDetail.returnCode == 0 && punterDetail.name) {
                cookieData.name = punterDetail.name
            }
        }
        catch (e) {
            console.error(e) // Okay to silently fail
        }

        // Set the new cookies.
        setLoginCookie(cookieData)
        document.dispatchEvent(new CustomEvent('auth:login', { detail: cookieData }))

        // Maybe redirect to 'next'
        const params = new URLSearchParams(window.location.search)
        const next = params.get('next')
        if (next) window.location.href = next

        setError(null) // Calls update() too
    }
}

function handleLogout(event) {
    event.preventDefault()
    logout()
}

function isNyraBetsUser() {
    if (!punter()) return false
    try {
        var val = JSON.parse(punter())
        return val && val.isNyraBetsUser
    } 
    catch (e) {
        return false
    }
}

const isLoggedIn = () => !! punter()

function setLoginCookie(data) {
    setCookie(punterKey, JSON.stringify(data))
}

function getLoginCookieData() {
    var data = punter();
    if (data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    }
    return null;
}

function logout() {
    setCookie('jwt', 'logout', true)
    setCookie(punterKey, 'logout', true)
    document.dispatchEvent(new Event('auth:logout'))
    update()
}

export default {
    init,
    isNyraBetsUser
}


// ----------------------------------------------------------------------------


// Utilities

// - Some of these might be reduntend, but I'm copying them from the previous,
//   site's implementation in order to smooth out the porting and debugging. CW

/**
 * Formats an expire string for a cookie.
 *
 * @param {Date} date
 * @return {String}
 */
function formatCookieExpiresString(date) {
    if (!date) {
      date = new Date();
      // Expire cookie in 14 days
      date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
    }
    return date.toUTCString();
  }
  
  /**
   * Get the root domain of the current location.
   *
   * NOTE: This will only succeed on single-suffix domains, like example.com.
   * It will fail spectacularly on domains like example.co.uk.
   *
   * @param {String} domain
   * @return {String}
   */
  function getRootDomain(domain) {
    if (!domain) {
      domain = location.hostname;
    }
    return domain.split('.').reverse().splice(0, 2).reverse().join('.');
  }
  
  /**
   * Set a cookie.
   *
   * @param {String} key
   * @param {String} value
   * @param {Boolean} expire Expire the cookie immediately.
   */
  function setCookie(key, value, expire) {
    var expires;
    if (expire === true) {
      expires = formatCookieExpiresString(new Date('Thu, 01 Jan 1970 00:00:01 GMT'));
    } else {
      expires = formatCookieExpiresString();
    }
    const newCookie = key + '=' + value + '; expires=' + expires + '; path=/'
    document.cookie = newCookie;
  }
  
  /**
   * Get a cookie value.
   *
   * @param {String} key
   * @return {String|null}
   */
  function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
  }
