import Swiper from 'swiper'
import { getCookie } from './cookies'
import '../styles/home.css'

const cookieKey = 'activeHomeSlide';

const q = {
    TRIGGER: '[data-home-slider-trigger]',
    STATE: '[data-home-slider-state]',
};

const state = {
    activeIndex: 0
};

function init() {
    state.activeIndex = getCookie(cookieKey, 0);

    const swiper = new Swiper("[data-home-slider-slides]", {
        simulateTouch: false,
        effect: 'fade',
        initialSlide: state.activeIndex,
        observer: true, // Really only needed for admin UX
        fadeEffect: {
            crossFade: true
        },
    });

    const triggers = document.querySelectorAll(q.TRIGGER);
    triggers.forEach((trigger, i) => {
        trigger.addEventListener('click', event => {
            event.preventDefault();
            state.activeIndex = i;
            swiper.slideTo(i);
            update();
        });
    });

    update();
}

function update() {
    const { activeIndex = 0 } = state;

    const triggers = document.querySelectorAll(q.TRIGGER);
    triggers.forEach((trigger, i) => {
        trigger.dataset.open = activeIndex == i;
    });

    const watchers = document.querySelectorAll(q.STATE);
    watchers.forEach(watcher => {
        watcher.dataset.activeIndex = activeIndex;
    });

    document.cookie = `${cookieKey}=${activeIndex}`;
}

export default {
    init
};