import "../styles/index.css";
import 'swiper/css/bundle';

import Sliders from '../components/sliders';
import Home from '../components/home';
import Megamenu from '../components/megamenu';
import Navigator from "../components/navigator";
import Accordion from "../components/accordion";
import Lightbox from "../components/lightbox";
import Calendar from "../components/calendar";
import Dropdowns from "../components/dropdown";
import Auth from "../components/auth"
import Stakes from "../components/stakes"
import Racing from "./racing"
import { getCookie } from "../components/cookies";

window.htmx = require('htmx.org')

if (!window.NYRADidInit) {
    Sliders.init()
    Home.init()
    Megamenu.init()
    Navigator.init()
    Accordion.init()
    Lightbox.init()
    Calendar.init()
    Dropdowns.init()
    Auth.init()
    Racing.init()
    Stakes.init()
}
window.NYRADidInit = true

if (typeof CMS !== 'undefined') {
    CMS.$(window).on('cms-content-refresh', function () {
        Sliders.init()
        Accordion.init()
        Dropdowns.init()
        Lightbox.init()
        Auth.init()
        Stakes.init()
    })
}

// Last Track Masquerade
const masqFlag = document.querySelector('[data-last-track-masquerade]');
if (masqFlag) {
    let mUrl = '/masquerade/';
    const lastTrack = getCookie('last_track');
    if (lastTrack) mUrl += `?track=${lastTrack}`
    htmx.default.ajax('GET', mUrl, {
        target: '[data-track-headers]',
        select: '[data-track-headers]',
        swap:'outerHTML'
    }).then(() => {
        Megamenu.init()
        Navigator.init()
        // Just hardcoding these for now since their well-known and stable
        document.body.classList.remove('theme-none');
        switch (lastTrack) {
            case 'aqueduct':
                document.body.classList.add('theme-aqu');
                break;
            case 'belmont':
                document.body.classList.add('theme-bel');
                break;
            case 'saratoga':
                document.body.classList.add('theme-sar');
                break;
            case 'belmont-stakes':
                document.body.classList.add('theme-bes');
                break;
            default:
                break;
        }
    })
}