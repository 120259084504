import SV from './sv'

function init() {
    const POINTER_THRESHOLD = 200; // A cheap, effective way to do "hover intent"
    let triggerIsHovered = false
    let targetIsHovered = false
    const menus = document.querySelectorAll('[data-megamenu-root]');
    menus.forEach(menu => {
      const state = {
        activeGroup: null,
        overlay: null
      };
  
      // Dismissal
      function requestDismissal() {
          if (!triggerIsHovered && !targetIsHovered) {
            state.activeGroup = null;
            update(menu, state);
          }
      }
  
      // Setup overlay
      // All menus dismissed on intentional hover
      state.overlay = document.createElement('div');
      state.overlay.dataset.megamenuOverlay = "";
      state.overlay.dataset.open = false;
      document.body.appendChild(state.overlay);
      state.overlay.addEventListener('pointerenter', requestDismissal);
  
      // Setup dismissers
      const dismissers = menu.querySelectorAll('[data-megamenu-dismiss]');
      new SV.HoverIntent(dismissers, {
        onEnter: function() {
          triggerIsHovered = targetIsHovered = false;
          requestDismissal();
        },
        onExit: function() {
          // Required. Do nothing
        },
      });
  
      // Setup triggers
      const triggers = menu.querySelectorAll('[data-megamenu-target]');
      new SV.HoverIntent(triggers, {
        onEnter: function(trigger) {
          const id = trigger.dataset.megamenuTarget;
          triggerIsHovered = true
          state.activeGroup = id;
          update(menu, state);
        },
        onExit: function() {
          triggerIsHovered = false;
          requestDismissal();
        },
        exitDelay: 500,
      });

      const targets = menu.querySelectorAll('[data-megamenu-ref]');
      targets.forEach(target => {
        target.addEventListener('pointerenter', event => {
          targetIsHovered = true;
        })
      })
      new SV.HoverIntent(targets, {
        onEnter: function() {
          // Required. Do nothing
        },
        onExit: function() {
          targetIsHovered = false;
          requestDismissal();
        },
        interval: 1,
        sensitivity: 1,
      });
  
      // Keyboard dismiss
      document.addEventListener('keydown', keydown => {
        if (keydown.key == "Escape") {
          state.activeGroup = null;
          update(menu, state);
        }
      });
    });
  }
  
function update(menu, state) {
    const { activeGroup } = state;
    let hasValidTarget = false;
    const triggers = menu.querySelectorAll('[data-megamenu-target]');
    const targets = menu.querySelectorAll('[data-megamenu-ref]');
    const watchers = menu.querySelectorAll('[data-megamenu-state]');
    targets.forEach(target => {
        const isTarget = target.dataset.megamenuRef == activeGroup;
        target.dataset.open = isTarget;
        if (isTarget) hasValidTarget = true;
    });
    triggers.forEach(trigger => {
        if (hasValidTarget) {
            trigger.dataset.open = false;
            if (trigger.dataset.megamenuTarget == activeGroup) trigger.dataset.open = true;    
        }
        else {
            trigger.dataset.open = null;
        }
    });
    watchers.forEach(watcher => watcher.dataset.open = hasValidTarget);
    state.overlay.dataset.open = hasValidTarget;
}
  
export default {
    init
};