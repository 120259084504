const selectors = {
    ROOT: '[data-calendar-root]',
    DAY: '[data-calendar-day]',
    DIALOG: '[data-calendar-dialog]',
    CLOSE: '[data-calendar-close]',
};

function init() {
    const root = document.querySelector(selectors.ROOT);
    if (root) {
        const days = root.querySelectorAll(selectors.DAY);
        days.forEach(day => {
            const dialog = day.querySelector(selectors.DIALOG);
            const close = day.querySelectorAll(selectors.CLOSE);
            if (dialog) {
                day.addEventListener("click", () => {
                    dialog.showModal();
                });
                close.forEach(trigger => {
                    trigger.addEventListener("click", (event) => {
                        event.stopImmediatePropagation();
                        dialog.close();
                    });
                });
            }
        });
        root.querySelectorAll('.swiper-pagination').forEach(item => {
            item.addEventListener("click", (event) => {
                event.stopImmediatePropagation();
            });
        });
    }
}

export default {
    init
};