function init() {
    document.querySelectorAll('[data-stakes-modal-root]').forEach(element => {
        const dialog = element.querySelector('[data-stakes-modal-content]')
        if (dialog) {
            element.querySelectorAll('[data-stakes-modal-trigger]').forEach(trigger => {
                trigger.addEventListener('click', event => {
                    event.preventDefault()
                    dialog.showModal()
                })
            })
            element.querySelectorAll('[data-stakes-modal-close]').forEach(closer => {
                closer.addEventListener('click', () => dialog.close())
            })
        }
    })
}

export default {
    init
}