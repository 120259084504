import Dropdowns from "../components/dropdown";
import Auth from "../components/auth"

const MODE_PREVIEW = 'preview'
const MODE_FULL = 'full'

const PIP_H_THRESHOLD = -0.525
const PIP_W_THRESHOLD = 1024

const IS_NYRA_BETS_USER = Auth.isNyraBetsUser()
const PREVIEW_TIME_LIMIT = 30

async function rcnUrl(filename, isReplay=false, isHD=true, angle='pan') {
    let result = false
    let url = '/rcn/video/?filename='+filename+'&replay='+isReplay+'&angle='+angle
    if (isHD) url += '&hd=true'
    try {
        const response = await fetch(url)
        if (!response.ok) throw new Error(`Video lookup failed: ${response.status}`)
        result = await response.text()
    } 
    catch (error) {
        console.error(error.message)
    }
    return result
}

function init() {
    // State
    let mode = MODE_FULL,
        pip = false,
        disablePip = false,
        currentReplay = null,
        lastReplay = null,
        countdown = PREVIEW_TIME_LIMIT,
        locked = false

    // ChannelView class
    // This class is used to manage the state of each channel in the player
    class ChannelView {
        active = false
        hasUpdated = false
        video = null
        vjs = null
        title = 'Channel'
        error = null

        constructor(index=0, active=false) {
            this.active = active
            this.video = player.querySelectorAll('video').item(index)
            if (this.video) {
                this.video.addEventListener('error', () => update())
                this.video.addEventListener('playing', () => update())
                videojs.hook('error', (player, err) => {
                    if (this.vjs && player == this.vjs) {
                        this.error = err
                        update()
                    }
                })
                this.vjs = videojs(this.video, { controls: true })
            }
            if (channelTitle.item(index)) this.title = channelTitle[index].textContent.trim()
        }
    
        async update(title, filename, rtmp, rtmpToken, manifest, manifestToken, vr) {
            this.title = title
            if (filename && this.vjs) {
                const url = await rcnUrl(filename)
                if (url) {
                    this.error = null
                    await this.preflight(url)
                    if (this.error) return
                    this.vjs.src({
                        type: 'application/x-mpegURL', 
                        src: url
                    })
                }
            }
            else if ((rtmp || manifest) && this.vjs) {
                if (manifest) {
                    this.error = null
                    await this.preflight(manifest)
                    if (this.error) return
                    this.vjs.src({
                        type: 'application/x-mpegURL', 
                        src: manifest
                    })
                }
                else {
                    // TODO: Support rtmp? I think it's only for flash and way outdated
                }
            }
            this.hasUpdated = true
        }

        mute(value = true) {
            if (this.vjs) this.vjs.muted(value)
        }

        shutdown() {
            if (this.vjs && this.active) {
                this.mute(true)
                this.vjs.pause()
            }
            this.active = false
        }

        // Run preflight on the video URL to check if it has valid content
        // VideoJS has no consistent error mode for this. It generates warnings, but there
        // is no way to hook into the warning event. So we have to do this manually. This
        // also prevents VideoJS from endless retries through the manifest, which is good. -CW
        async preflight(url) {
            try {
                const response = await fetch(url)
                if (!response.ok) {
                    this.mute(true)
                    this.error = true
                }
                const result = await response.text()
                if (result) {
                    // The world's worst M3U8 parser!
                    const lines = result.split('\n')
                    for (const line of lines) {
                        if (line.includes('https')) {
                            const chunk = await fetch(line)
                            // One bad apple spoils the bunch
                            if (!chunk.ok) {
                                this.vjs.pause()
                                this.mute(true)
                                this.error = true
                            }
                            break
                        }
                    }
                }
            } 
            catch (error) {
                this.mute(true)
                this.error = true
            }
        }
    }

    const player = document.querySelector('[data-rdl-player]')
    if (!player) return

    const modeSwitch = button('[data-rdl-mode]', () => toggleMode())
    const multiSwitch = button('[data-rdl-multi]', () => toggleMultiview())
    const pipSwitch = button('[data-rdl-pip-ability]', () => togglePipAbility())
    const channelButton = button('[data-rdl-channel]', handleChannel)
    const playButton = button('[data-rdl-playback]', handlePlay)
    const audioButton = button('[data-rdl-audio]', handleAudio)
    const channelTitle = player.querySelectorAll('[data-rdl-channel-title]')
    const countdownLabel = player.querySelectorAll('[data-rdl-countdown]')
    const canMulti = 'canMulti' in player.dataset

    // Initialize the channels
    const channels = [
        new ChannelView(0, true),
        new ChannelView(1),
    ]

    // Start with the first channel
    if (channelButton.item(0)) {
        channelButton[0].click()
    }

    // Countdown timer and lock for non NYB users
    setInterval(() => {
        if (countdown > 0) {
            countdown--
            update()
        }
        else if (!IS_NYRA_BETS_USER && !locked) {
            if (channels[0].vjs) channels[0].vjs.dispose()
            if (channels[1].vjs) channels[1].vjs.dispose()
            channels[1].active = false
            pip = false
            disablePip = true
            locked = true
            mode = MODE_PREVIEW
            update()
        }
    }, 1000)

    // Picture-in-Picture observer
    const pipObserver = new IntersectionObserver(handlePipObserver, {
        threshold: [.4,.425,.45,.475,.5]
    })
    pipObserver.observe(player)

    // Handle Picture-in-Picture observer
    function handlePipObserver(entries) {
        if (channels[1].active || !!channels[0].error || !channels[0].hasUpdated || disablePip) return
        if (entries.at(0)) {
            const { y, height, width } = entries[0].boundingClientRect
            if (y / height < PIP_H_THRESHOLD && width > PIP_W_THRESHOLD) pip = true
            else pip = false
            update()
        }
    }

    // Handle mode switching (DEPRECATED)
    function toggleMode() {
        mode = MODE_FULL
        update()
    }

    // Handle pip ability
    function togglePipAbility(){
        disablePip = !disablePip
        update()
    }

    // Handle multi-view
    async function toggleMultiview() {
        if (!canMulti) return
        const video2 = channels[1].video
        if (!channels[1].active && channelButton.item(1)) {
            channels[1].active = true
            update() // Update the UI before preflight
            const { filename, title, rtmp, rtmpToken, manifest, manifestToken, vr } = channelButton.item(1).dataset
            const channel = channels.at(1)
            await channel.update(title, filename, rtmp, rtmpToken, manifest, manifestToken, vr)
            video2.addEventListener('error', () => update())
            update() // Update the UI again after the channel has (tried to be) loaded
        }
        else {
            channels[1].shutdown()
            update()
        }
    }

    // Handle play/pause
    function handlePlay() {
        const index = Array.from(playButton).indexOf(this)
        if (channels.at(index)) {
            const vjs = channels[index].vjs
            if (vjs && channels[index].active) vjs.paused() ? vjs.play() : vjs.pause()
        }
        update()
    }

    // Handle channel selection
    async function handleChannel(event) {
        const { rdlChannel, filename, title, rtmp, rtmpToken, manifest, manifestToken, vr } = this.dataset
        let index = rdlChannel ? Number(rdlChannel) - 1 : 0
        const channel = channels.at(index)
        await channel.update(title, filename, rtmp, rtmpToken, manifest, manifestToken, vr)
        update()
    }

    // Handle audio mute
    function handleAudio() {
        const index = Array.from(audioButton).indexOf(this)
        if (channels.at(index)) {
            const vjs = channels[index].vjs
            if (vjs) {
                vjs.muted(!vjs.muted())
            } 
        }
        update()
    }

    // Update all the watchers with the current state
    function update() {
        if (channels[1].active || disablePip) pip = false; // Multi-view always disabled pip
        [
            player, 
            ...player.querySelectorAll('[data-rdl-player-watch]')
        ].forEach(watcher => {
            if (watcher.dataset.rdlPlayerWatch?.includes('pip'))        watcher.dataset.pip = pip
            if (watcher.dataset.rdlPlayerWatch?.includes('pip'))        watcher.dataset.disablePip = disablePip
            if (watcher.dataset.rdlPlayerWatch?.includes('mode'))       watcher.dataset.mode = mode
            if (watcher.dataset.rdlPlayerWatch?.includes('multi'))      watcher.dataset.multi = channels[1].active
            if (watcher.dataset.rdlPlayerWatch?.includes('canMulti'))   watcher.dataset.canMulti = canMulti
            if (watcher.dataset.rdlPlayerWatch?.includes('locked'))     watcher.dataset.locked = locked
            channels.forEach((channel, i) => {
                if (watcher.dataset.rdlPlayerWatch?.includes('videoError')) watcher.dataset[`videoError${i+1}`] = channel.active && !!channel.error
                if (!locked) {
                    if (watcher.dataset.rdlPlayerWatch?.includes('playing'))    watcher.dataset[`playing${i+1}`] = !channel.vjs?.paused()
                    if (watcher.dataset.rdlPlayerWatch?.includes('muted'))      watcher.dataset[`muted${i+1}`] = !channel.vjs?.muted()
                }
            })
        })
        if (!IS_NYRA_BETS_USER) {
            countdownLabel.forEach(label => {
                label.innerHTML = countdown > 0 ? countdown : ''
            })
        }
        channelTitle.forEach((title, i) => {
            const ci = title.dataset.rdlChannelTitle
            if (ci && channels.at(ci-1)) {
                title.innerText = channels[ci-1].title
            }
        })
        // Update replay
        maybeUpdateReplay()
    }

    // Helper function to add event listeners to a group of buttons
    function button(selector, fn) {
        const buttons = player.querySelectorAll(selector)
        buttons.forEach(button => button.addEventListener('click', fn))
        return buttons
    }

    // MARK: Replays ----------------------------------------------------------
    function initReplays() {
        currentReplay = null
        const replayTriggers = document.querySelectorAll('[data-replay-trigger]')
        const replayDismissers = document.querySelectorAll('[data-replay-dismiss]')
        replayTriggers.forEach(trigger => trigger.addEventListener('click', handleReplay))
        replayDismissers.forEach(dismisser => dismisser.addEventListener('click', () => {
            currentReplay = null
            update()
        }))
    }
    initReplays()

    async function handleReplay(event) {
        const { replayAngle, replayFilename } = this.dataset
        if (replayAngle && replayFilename) {
            const replayUrl = await rcnUrl(replayFilename, true, true, replayAngle)
            if (replayUrl) {
                if (channels[0]?.video) channels[0].video.muted = true
                if (channels[1]?.video) channels[1].video.muted = true
                currentReplay = replayUrl
                update()
            }
        }
    }

    function maybeUpdateReplay() {
        let replayer = null
        const root = document.querySelector('[data-replay-root]')
        if (root) replayer = root.querySelector('video')
        const watchers = document.querySelectorAll('[data-replay-watch]')
        if (currentReplay) {
            if (!replayer) {
                const embed = document.createElement('video')
                embed.controls = true
                embed.playsInline = true
                embed.className = 'relative z-10 w-full h-auto aspect-[16/9] my-6 rounded-md video-js'
                root.appendChild(embed)
                const vjs = videojs(embed)
                vjs.src({
                    type: 'application/x-mpegURL', 
                    src: currentReplay
                })
                vjs.ready(function() {
                    vjs.play()
                })
            }
            else if (lastReplay != currentReplay) {
                const vjs = videojs(replayer)
                vjs.src({
                    type: 'application/x-mpegURL', 
                    src: currentReplay
                })
                vjs.ready(function() {
                    vjs.play()
                })
            }
            watchers.forEach(watcher => watcher.dataset.active = true)
        }
        else {
            if (replayer) {
                const removal = root.querySelector('.video-js')
                removal.parentNode.removeChild(removal)
            }
            watchers.forEach(watcher => watcher.dataset.active = false)
        }
        lastReplay = currentReplay
    }

    document.body.addEventListener('htmx:afterSwap', () => {
        Dropdowns.init()
        initReplays()
    })

    update()
}

export default {
    init,
    rcnUrl
}