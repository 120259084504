const selectors = {
    ROOT: '[data-navigator-root]',
    TRIGGERS: '[data-navigator-trigger]',
    CONTENT: '[data-navigator-content]',
    WATCHERS: '[data-navigator-watch]'
};

function init() {
    const navigator = document.querySelector(selectors.ROOT);
    if (!navigator) return;

    const state = {
        open: navigator.dataset.open || false
    };
    const triggers = navigator.querySelectorAll(selectors.TRIGGERS);
    const content = navigator.querySelectorAll(selectors.CONTENT);
    const watchers = document.querySelectorAll(selectors.WATCHERS);

    triggers.forEach(trigger => {
        trigger.addEventListener('click', event => {
            event.preventDefault();
            toggle();
        });
    });

    function toggle() {
        state.open = !state.open;
        update();
    }

    function update() {
        [navigator, ...triggers, ...content, ...watchers].map(item => item.dataset.open = state.open);
    }
}

export default {
    init
};