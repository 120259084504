const rootSelector = '[data-accordion-root]';

const selectors = {
    'item': selector('item'),
    'handle': selector('handle'),
    'content': selector('content'),
    'state': selector('state'),
};

function selector(name) {
    return `[data-accordion-${name}]:not(& ${rootSelector} [data-accordion-${name}])`;
}

function initAccordion(element) {
    const state = new Set();
    const mode = element.dataset.accordionMode;
    const unclosable = 'accordionUnclosable' in element.dataset;

    const heightObserver = new ResizeObserver(function() {
        update()
    });

    element.querySelectorAll(rootSelector).forEach(innerAccordion => {
        heightObserver.observe(innerAccordion);
    });

    element.querySelectorAll(selectors.item).forEach(item => {
        if (item.dataset.open == 'true') state.add(item);
        // Update state when a handle is clicked
        item.querySelectorAll(selectors.handle).forEach(handle => {
            handle.addEventListener('click', event => {
                if (unclosable && state.size == 1 && state.has(item)) return;
                const op = state.has(item) ? 'delete' : 'add';
                if (mode == 'single') {
                    maybeScrollIntoView(handle);
                    state.clear();
                }
                state[op](item);
                update();
                event.preventDefault();
            });
        });
    });

    function update() {
        element.querySelectorAll(selectors.item).forEach(item => {
            const isOpen = state.has(item);
            item.dataset.open = isOpen;
            window.requestAnimationFrame(() => {
                item.querySelectorAll(`${selectors.content}, ${selectors.handle}, ${selectors.state}`).forEach(content => {
                    // Expose expected height of open content to aid in CSS animation
                    content.style.setProperty('--accordion-open-height', `${content.scrollHeight}px`);
                    content.dataset.open = isOpen;
                });
            });
        });
    }

    function maybeScrollIntoView(handle) {
        if (state.size == 1) {
            const previous = Array.from(state)[0].querySelector(selectors.content);
            const nextY = handle.getBoundingClientRect().top;
            const scrollY = window.scrollY;
            if (
                previous && // Exists
                previous.getBoundingClientRect().top < nextY && // Is above the next item
                nextY - previous.scrollHeight < scrollY // Is tall enough to scroll off the page
            ) {
                window.scrollTo({
                    top: scrollY + nextY - previous.scrollHeight,
                    behavior: 'smooth'
                });
            }
        }
    }

    update();

    window.addEventListener('resize', () => {
        // This maintains the intrinsic size of items on window size changes
        update();
    })
}

function init() {
    document.querySelectorAll(rootSelector).forEach(element => {
        initAccordion(element);
    });
}

export default {
    init
};