// A Very Very Simple Dropdown

const selectors = {
    ROOT: '[data-dropdown-root]',
    TRIGGER: '[data-dropdown-trigger]',
    DISMISS: '[data-dropdown-dismiss]',
    CONTENT: '[data-dropdown-content]',
}

function update(dropdown, state) {
    const triggers = dropdown.querySelectorAll(selectors.TRIGGER)
    const contents = dropdown.querySelectorAll(selectors.CONTENT)
    dropdown.dataset.open = state.active
    triggers.forEach(trigger => trigger.dataset.open = state.active)
    contents.forEach(content => content.dataset.open = state.active)
}

function init() {
    const dropdowns = document.querySelectorAll(selectors.ROOT)

    dropdowns.forEach(dropdown => {
        if (!dropdown.dataset.initialized) {
            dropdown.dataset.initialized = true
            const state = { active: false }
            const triggers = dropdown.querySelectorAll(selectors.TRIGGER)
            const dismissers = dropdown.querySelectorAll(selectors.DISMISS)
            triggers.forEach(trigger => {
                trigger.addEventListener('mousedown', event => {
                    state.active = !state.active
                    update(dropdown, state)
                })
            })
            dismissers.forEach(dismisser => {
                dismisser.addEventListener('click', event => {
                    state.active = false
                    update(dropdown, state)
                })
            })
            // Off-click
            window.addEventListener('mousedown', event => {
                if (event.target != dropdown && !dropdown.contains(event.target)) {
                    state.active = false
                    update(dropdown, state)
                }
            })
            // ESC dismiss
            window.addEventListener('keydown', keydown => {
                if (keydown.key == "Escape") {
                    state.active = false
                    update(dropdown, state)
                }
            })
            // Support pointerdown, pointermove, pointerup interactions on <a>
            window.addEventListener('mouseup', event => {
                if (event.target.matches('a[href]')) {
                    event.target.click()
                    state.active = false
                    update(dropdown, state)
                }
            })
        }
    })
}

export default {
    init
}