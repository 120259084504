import * as basicLightbox from 'basiclightbox'

function init() {
    const triggers = document.querySelectorAll('[data-lightbox-trigger]')
    triggers.forEach(trigger => {
        trigger.addEventListener('click', (event) => {
            event.preventDefault()
            const src = event.target.getAttribute('href')
            const instance = basicLightbox.create(`
                <img src="${src}">
            `)
            instance.show()
        })
    })
}

export default {
    init
}