import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import "../styles/swiper.css"

// Generic slider types

function init() {
    const simpleSwiper = new Swiper(".simple-swiper", {
        modules: [Pagination, Navigation],
        spaceBetween: 8,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    const tinySwiper = new Swiper(".tiny-swiper", {
        modules: [Pagination],
        spaceBetween: 24,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    })
    const imageSwiper = new Swiper(".image-swiper", {
        modules: [Pagination, Navigation],
        spaceBetween: 8,
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

    const items = document.querySelectorAll('.item-swiper')
    items.forEach(item => {
        const itemSwiper = new Swiper(item, {
            modules: [Pagination, Navigation],
            spaceBetween: 8,
            rewind: true,
            slidesPerView: 1,
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                1024: {
                    slidesPerView: item.dataset.slideSize ?? 2,
                    slidesPerGroup: item.dataset.slideSize ?? 2,
                    spaceBetween: 16,
                },
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    })

    const newsSliders = document.querySelectorAll('[data-news-slider]')
    newsSliders.forEach(item => {
        const itemSwiper = new Swiper(item, {
            modules: [Pagination, Navigation],
            spaceBetween: 8,
            rewind: true,
            slidesPerView: 1,
            breakpoints: {
                720: {
                spaceBetween: 24,
                slidesPerView: item.dataset.newsSize ?? 3,
                    slidesPerGroup: item.dataset.newsSize ?? 3,
                    spaceBetween: 16,
                },
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    })
}

export default {
    init
};
